import { scroller } from 'react-scroll';

const screens = {
	Intro: {
		name: 'Intro',
	},
	Portfolio: {
		name: 'Portfolio',
	},
	Hobby: {
		name: 'Hobby',
	},
};

const scrollTo = (section: keyof typeof screens) => {
	scroller.scrollTo(section, {
		duration: 800,
		smooth: 'easeInOutQuart',
		delay: 0,
	});
};

export { screens, scrollTo };
