import { FC } from 'react';
import ss from './icon.module.scss';

type TIconName =
	| 'computer'
	| 'expand'
	| 'android'
	| 'ios'
	| 'web'
	| 'game'
	| 'database'
	| 'github'
	| 'twitter'
	| 'linkedin'
	| 'copyleft';

type TProps = {
	icon: TIconName;
	style?: any;
	className?: string;
	tint?: string;
	onClick?: any;
};

export const IconComponent: FC<TProps> = ({ icon, className, tint, ...props }) => {
	switch (icon) {
		case 'computer':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe678;
				</span>
			);
		case 'android':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe669;
				</span>
			);
		case 'game':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe67a;
				</span>
			);
		case 'ios':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe626;
				</span>
			);
		case 'web':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe64a;
				</span>
			);
		case 'expand':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe607;
				</span>
			);
		case 'github':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe898;
				</span>
			);
		case 'twitter':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe60a;
				</span>
			);
		case 'linkedin':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe812;
				</span>
			);
		case 'database':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe60d;
				</span>
			);
		case 'copyleft':
			return (
				<span
					className={`${ss.iconfont} ${className}`}
					style={{
						color: tint,
					}}
					{...props}
				>
					&#xe70c;
				</span>
			);
		default:
			return <div {...props} />;
	}
};
