export const portfolioMockData: { [k: string]: NSData.TPortfolio } = {
	game: {
		title: 'Game Development',
		projects: [
			{
				name: 'Capture X',
				previewImage:
					'https://yaji.gallerycdn.vsassets.io/extensions/yaji/cocos-effect/0.3.0/1645429647425/Microsoft.VisualStudio.Services.Icons.Default',
				url: 'https://chang.is-a.dev/dh_hack2022',
				rating: 4.2,
				icons: [
					{
						name: 'android',
						tint: 'springgreen',
					},
					{
						name: 'ios',
						tint: 'gainsboro',
					},
					{
						name: 'web',
						tint: 'honeydew',
					},
					{
						name: 'game',
						tint: 'steelblue',
					},
				],
			},
		],
	},
	mobile: {
		title: 'Mobile App',
		projects: [
			{
				name: 'VG9LAB App',
				previewImage:
					'https://play-lh.googleusercontent.com/HEQF1REWwcEdwIt3PSkZc7yaRrGvyPH4ZfamL_Xnu1aODZB6ApXgZnOHik6u5VoysKE6=w240-h480-rw',
				url: 'https://vg9lab.com',
				rating: 4.7,
				icons: [
					{
						name: 'android',
						tint: 'springgreen',
					},
					{
						name: 'ios',
						tint: 'gainsboro',
					},
				],
			},
		],
	},
	server: {
		title: 'Backend',
		projects: [
			{
				name: 'VG9LAB Server',
				previewImage:
					'https://ih1.redbubble.net/image.399309883.8609/st,small,507x507-pad,600x600,f8f8f8.u2.jpg',
				url: 'https://vg9lab.herokuapp.com/api',
				rating: 3.5,
				icons: [
					{
						name: 'database',
						tint: 'gainsboro',
					},
				],
			},
		],
	},
};
