import { ReactComponent as BasketballSVG } from 'assets/svg/basketball.svg';
import { ReactComponent as FitnessSVG } from 'assets/svg/fitness.svg';
import { ReactComponent as DadBabySVG } from 'assets/svg/dadbaby.svg';

export const hobbiesMockData = [
	{
		title: 'Basketball',
		desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate laudantiumvoluptatibus omnis quaerat!',
		svg: <BasketballSVG />,
		to: '/basketball',
	},
	{
		title: 'Lifting',
		desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
		svg: <FitnessSVG />,
		to: 'lifting',
	},
	{
		title: 'Daddy',
		desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Id?',
		svg: <DadBabySVG />,
		to: 'seedling',
	},
];
