import { FC } from 'react';
import { useParams } from 'react-router-dom';

type TProps = {};
type TParams = {
	id: string;
};

export const SeedlingDetailScreen: FC<TProps> = () => {
	const params = useParams<TParams>();
	return (
		<div
			style={{ display: 'flex', alignItems: 'center', height: '25vh' }}
		>{`SeedlingDetailScreen: ${params.id}`}</div>
	);
};
