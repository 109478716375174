import { FC } from 'react';

type TProps = {};

export const SeedlingHomeScreen: FC<TProps> = () => {
	return (
		<div style={{ display: 'flex', alignItems: 'center', height: '25vh' }}>
			{`SeedlingHomeScreen`}
		</div>
	);
};
