import { FC } from 'react';

import { Icon } from 'components';
import ss from './footer.module.scss';

type TProps = {};

export const FooterWidget: FC<TProps> = () => {
	return (
		<div className={ss.footer}>
			<div>
				<Icon icon="copyleft" className={ss.copyleft} tint="#222" />
				<span>
					2022 &nbsp;<b className="logo-zh">{'一只沙小贱'.split('').reverse().join('')}</b>
				</span>
			</div>
			<div>
				<a href="https://github.com/bobbyliu117" target="_blank" rel="noreferrer">
					<Icon icon="github" className={ss.icon} tint="#222" />
				</a>
				<a href="https://twitter.com/changisadev" target="_blank" rel="noreferrer">
					<Icon icon="twitter" className={ss.icon} tint="skyblue" />
				</a>
				<a href="https://www.linkedin.com/in/bobbychangliu/" target="_blank" rel="noreferrer">
					<Icon icon="linkedin" className={ss.icon} tint="midnightblue" />
				</a>
			</div>
		</div>
	);
};
