import { screens } from '../home.screen.config';

import { Element } from 'react-scroll';
import ss from './hobby.module.scss';
import { hobbiesMockData } from './hobbies.mockData';
import { Row } from 'react-bootstrap';
import { HobbyItemView } from './hobby-item/HobbyItemView';

const { name } = screens.Hobby;

export const HobbySection = () => {
	return (
		<>
			<Element name={name} />
			<div className={ss.root}>
				<h2 className="text-center py-5">{name}</h2>
				<Row className="gy-5 align-items-center">
					{hobbiesMockData.map((item) => (
						<HobbyItemView key={item.title} hobbyData={item} />
					))}
				</Row>
			</div>
		</>
	);
};
