import { FC } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ss from './hobby-item.module.scss';

type TProps = {
	hobbyData: { title: string; desc: string; svg: JSX.Element; to: string };
};

export const HobbyItemView: FC<TProps> = ({ hobbyData: { title, desc, svg, to } }) => {
	return (
		<Col xm={12} lg={4}>
			<Link to={to}>
				<Card className={'p-3 my-3'}>
					<Card.Header className={`${ss.header} hstack`}>{svg}</Card.Header>
					<Card.Title>{title}</Card.Title>
					<Card.Text as="div" className={ss.desc}>
						{desc}
					</Card.Text>
				</Card>
			</Link>
		</Col>
	);
};
