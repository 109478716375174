import { FC } from 'react';
import { Element } from 'react-scroll';
import { Row } from 'react-bootstrap';

import { screens } from '../home.screen.config';
import { ReactComponent as CodeSVG } from 'assets/svg/code.svg';
import ss from './portfolio.module.scss';
import { portfolioMockData } from './portfolio.mockData';
import { ProjectItemView } from './project-item/ProjectItemView';

const { name } = screens.Portfolio;

const PortfolioItem: FC<{
	section: { title: string; projects: Array<NSData.TProject> };
}> = ({ section: { title, projects } }) => {
	return (
		<div className={ss.section}>
			<h3>{title}</h3>

			<Row className="gy-4">
				{projects.map((project) => (
					<ProjectItemView key={project.name} project={project} />
				))}
			</Row>
		</div>
	);
};

export const PortfolioSection = () => {
	return (
		<>
			<Element name={name} />
			<div className={ss.root}>
				<div className={ss.header}>
					<CodeSVG className={ss.illustrate} />
					<h2>{name}</h2>
				</div>
				{Object.keys(portfolioMockData).map((key) => (
					<PortfolioItem key={key} section={portfolioMockData[key]} />
				))}
			</div>
		</>
	);
};
