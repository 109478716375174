import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BasketballScreen } from 'screens/basketball/BasketballScreen';
import { HomeScreen } from 'screens/home/HomeScreen';
import { LiftingScreen } from 'screens/lifting/LiftingScreen';
import { SeedlingDetailScreen } from 'screens/seedling-detail/SeedlingDetailScreen';
import { SeedlingHomeScreen } from 'screens/seedling/SeedlingHomeScreen';

export const App = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomeScreen />} />
					<Route path="/basketball" element={<BasketballScreen />} />
					<Route path="/lifting" element={<LiftingScreen />} />
					<Route path="/seedling" element={<SeedlingHomeScreen />} />
					<Route path="/seedling/:id" element={<SeedlingDetailScreen />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};
