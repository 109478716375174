import { IntroSection } from './intro/IntroSection';
import { PortfolioSection } from 'screens/home/portfolio/PortfolioSection';
import { HobbySection } from 'screens/home/hobby/HobbySection';
import { HeaderWidget } from './header/HeaderWidget';
import { FooterWidget } from './footer/FooterWidget';

export const HomeScreen = () => {
	return (
		<>
			<HeaderWidget />
			<IntroSection />
			<PortfolioSection />
			<HobbySection />
			<FooterWidget />
		</>
	);
};
