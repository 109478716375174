import me from 'assets/images/me.jpeg';
import { Element } from 'react-scroll';
import { Icon } from 'components';
import { screens, scrollTo } from '../home.screen.config';
import styles from './intro.module.scss';

const { name } = screens.Intro;

export const IntroSection = () => {
	return (
		<>
			<Element name={name} />
			<div className={styles.header}>
				<div className={styles.card}>
					<div>
						<div>
							<img src={me} alt="me" />
						</div>
						<div>
							<span>LIU, CHANG</span>
							<span>📍 LOS ANGELES</span>
						</div>
					</div>
				</div>
				<span className={styles.headerTitle}>Hello, nice meeting you!</span>
				<Icon icon="expand" className={styles.expand} onClick={() => scrollTo('Portfolio')} />
			</div>
		</>
	);
};
