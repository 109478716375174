import { Icon, Ratings } from 'components';
import { FC } from 'react';
import { Col } from 'react-bootstrap';

import ss from './project-item.module.scss';

export const ProjectItemView: FC<{ project: NSData.TProject }> = ({ project }) => {
	return (
		<Col xs={6} md={4} lg={3}>
			<a
				href={project.url}
				target="_blank"
				rel="noreferrer"
				className={ss.card}
				style={{
					backgroundImage: `url(${project.previewImage})`,
				}}
			>
				<div className={ss.label}>
					<h4>{project.name}</h4>
					<div>
						{project.icons.map((icon: any) => (
							<Icon key={icon.name} icon={icon.name} className={ss.icon} tint={icon.tint} />
						))}
					</div>
					<Ratings value={project.rating} className="py-1" />
				</div>
			</a>
		</Col>
	);
};
