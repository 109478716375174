import { useLottie } from 'lottie-react';
import { FC, useCallback, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import { scrollTo, screens } from '../home.screen.config';
import lottieMenu from 'assets/lottie/menu.json';
import ss from './header.module.scss';
import { Icon } from 'components';

type TProps = {};

export const HeaderWidget: FC<TProps> = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const { View, playSegments } = useLottie({
		animationData: lottieMenu,
		autoplay: false,
	});

	const toSection = useCallback(
		(section: any) => {
			if (isMenuOpen) {
				playSegments([70, 0], true); // Close animation
				setMenuOpen(false);
			}
			scrollTo(section);
		},
		[isMenuOpen, playSegments]
	);

	return (
		<header>
			<Navbar className={ss.nav} fixed="top" variant="dark" expand="lg" expanded={isMenuOpen}>
				<Navbar.Brand href="/" className={`${ss.brand} ps-3 hstack`}>
					<Icon icon="computer" className={`${ss.logo}`} /> Chang's
				</Navbar.Brand>
				<Navbar.Toggle
					style={{ borderWidth: 0, boxShadow: 'none' }}
					aria-controls="basic-navbar-nav"
				>
					<div
						style={{ width: '36px', height: '36px' }}
						onClick={() => {
							playSegments(isMenuOpen ? [70, 0] : [0, 70], true);
							setMenuOpen((prev) => !prev);
						}}
					>
						{View}
					</div>
				</Navbar.Toggle>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto pe-3">
						{Object.keys(screens).map((k) => (
							<Nav.Link
								style={{ color: 'white' }}
								className="py-3 text-center"
								key={k}
								onClick={() => toSection(k)}
							>
								{k}
							</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</header>
	);
};
