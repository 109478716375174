import { ReactComponent as IconStar } from 'assets/svg/star.svg';
import { FC } from 'react';
import ss from './ratings.module.scss';

type TProps = {
	value: number;
	max?: number;
	className?: string;
};

export const RatingsComponent: FC<TProps> = ({ value, max = 5, className }) => {
	const percentage = Math.round((value / max) * 100);

	return (
		<div className={`${ss.container} ${className || ''}`}>
			{Array.from(Array(max).keys()).map((v) => (
				<IconStar key={v} />
			))}
			<div
				className={ss.overlay}
				style={{
					width: `${100 - percentage}%`,
				}}
			/>
		</div>
	);
};
