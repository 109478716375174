import { type FC } from 'react';

type TProps = {
	bg?: string;
	title?: string;
	h?: string;
};

export const PlaceholderComponent: FC<TProps> = ({ bg, title, h }) => (
	<div
		style={{
			backgroundColor: bg,
			height: h,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		{title!.toUpperCase()}
	</div>
);

PlaceholderComponent.defaultProps = {
	bg: 'crimson',
	title: 'Placeholder',
	h: '100vh',
};
